import React from "react"
import { Link } from "gatsby"
import { css } from "@emotion/core"
import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <div
      css={css`
        display: flex;
        height: 90vh;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      `}
    >
      <div
        css={css`
          color: black;
          font-size: 10vw;
          display: block;
          margin: 130px;
        `}
      >
        TogoBee
      </div>
      <div
        css={css`
          color: black;
          font-size: 3vw;
          display: block;
        `}
      >
        Commision-free ordering system & website
      </div>
    </div>
  </Layout>
)

export default IndexPage
